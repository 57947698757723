import IconList from "../components/IconList";
import Navigator from "../components/Navigator";

export default function Icons() {
  return (
    <div>
      <Navigator />
      <IconList />
    </div>
  )
}
