import Navigator from "../components/Navigator";
import TemplateForm from "../components/TemplateForm";

export default function CreateTemplate() {
  return (
    <div>
      <Navigator />
      <TemplateForm />
    </div>
  )
}
