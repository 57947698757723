import Navigator from '../components/Navigator';
import PageList from '../components/PageList';

export default function Me() {
  return (
    <div>
        <Navigator />
        <PageList />
    </div>
  )
}
