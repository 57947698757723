import { useState } from "react";
import toast from "react-hot-toast";
import api from "../../services/api";
import './templateForm.css'

export default function TemplateForm() {
  const [formState, setFormState] = useState({
    name: '',
    html: '',
    css: '',
    animes_available: false
  })

  const handleCheckboxChange = () => {
    setFormState((prevState) => ({ ...prevState, 'animes_available': !formState.animes_available }));
  };

  const onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === 'animes_available') {
      setFormState((prevState) => ({ ...prevState, 'animes_available': !formState.animes_available }));
    } else {
      setFormState((prevState) => ({ ...prevState, [name]: value }));
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();

    api.post('/api/templates', formState).then(({ data }) => {
      toast.success('Template salvo com sucesso!')
    }).catch((err) => {
      toast.error('Tivemos um problema, tente novamente mais tarde.')
    });
  }

  return (
    <main>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-bookmark bookmark-form">
              <span>Início/ Templates / </span>
              <b>Novo template</b>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="sub-title title-template">
              Novo Template
            </div>
            <div className="new-page-card card-form">
              <form>
                <label>Nome</label>
                <input name="name" value={formState.name} onChange={onChange} type="text" />

                <label htmlFor="name">Código HTML</label>
                <textarea name="html" value={formState.html} onChange={onChange} id="comment" rows="5" cols="49"></textarea>
                
                <label htmlFor="name">Código CSS</label> 
                <textarea name="css" value={formState.css} onChange={onChange} id="comment" rows="5" cols="49"></textarea>
                
                <label className="field-animes">
                  <input name="animes_available" checked={formState.animes_available} onChange={handleCheckboxChange} type="checkbox"/>
                  <span>
                    Disponível no Animes
                  </span>
                </label>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="btn-save">
              <button onClick={onSubmit} type="button">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
