import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../services/api";
import toast from "react-hot-toast";
import addNew from '../../assets/sinal-adicionar.svg'

export default function IconList() {
  const [icons, setIcons] = useState([]);

  useEffect(() => {
    api.get('/api/icons/find').then(({ data }) => {
      setIcons(data);
    }).catch(() => {
      toast.error('Houve um problema ao carregar as informações.')
    })
  }, [])

  return (
    <main>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-bookmark">
              <span>Início/ </span>
              <b>Ícones</b>
            </div>
          </div>
        </div>
      <div className="row">
        <div className="col-md-12">
          <div className="title">
            <h1>Ícones</h1>
            <Link to={`/dashboard/create-icon`}>
              <span>Criar novo ícone</span>
              <button className="btn-new">
                <img src={addNew} alt="sinal de adicionar"/>
              </button>
            </Link>
          </div>
        </div>
      </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table-list table table-striped">
              <thead>
                <tr>
                  <th>
                    Nome
                  </th>
                  <th>
                    Atualizado em
                  </th>
                  <th>
                   Ícone
                  </th>
                </tr>
              </thead>
              <tbody>
                {icons.map(icon => (
                  <tr key={icon.id}>
                    <td>
                      <p>
                        {icon.name}
                      </p>
                    </td>
                    <td>
                      <p>
                        {new Date(icon.updated_at).toLocaleDateString()}
                      </p>
                    </td>
                    <td>
                      <img src={icon.src} alt={icon.name} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
    </div>
    </main>
  )
}
