import { Link } from "react-router-dom";
import './navigator.css';
import {Auth} from 'aws-amplify';
import logo from '../../assets/logo-minebio.png'
import iconPage from '../../assets/icon-pagina.png'
import iconTemplate from '../../assets/icon-codigo.png'
import iconImage from '../../assets/icon-imagens.png'
import iconExit from '../../assets/icon-exit.png'

export default function Navigator() {
  const logout = async () => {
    await Auth.signOut()
    window.location.reload()
  }

  return (
    <div className="navigator">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <nav>
              <div className="logo">
                <Link to="/dashboard">
                  <img className="img-fluid" src={logo} alt="Logotipo escrito Mineb.io" />
                </Link>
              </div>    
              <ul>
                <li>
                  <Link to="/dashboard">
                    <img className="img-fluid" src={iconPage} alt=""/>
                    <span>Minhas páginas</span>
                  </Link>
                </li>
                <li>
                  <Link to="/dashboard/templates">
                    <img className="img-fluid" src={iconTemplate} alt=""/>
                    <span>Templates</span>
                  </Link>
                </li>
                <li>
                  <Link to="/dashboard/icons">
                    <img className="img-fluid" src={iconImage} alt=""/>
                    <span>Ícones</span>
                  </Link>
                </li>
              </ul>

              <div className="logout">
                <Link to="">
                  <img src={iconExit} alt="" onClick={logout}/>
                </Link>
              </div>
            </nav>
          </div>
        </div>  
      </div>
    </div>
  )
}
