import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import api from "../../services/api";
import './templateList.css';
import addNew from '../../assets/sinal-adicionar.svg'
import toast from "react-hot-toast";

const demoPage = {
  name: "Seu Nome",
  title: "Título da Página",
  username: "username01",
  bio: "Usuário do Linkes",
  avatar: "https://www.tailwind-kit.com/icons/rocket.svg",
  links: [
    {
      label: "Instagram",
      icon: "https://www.tailwind-kit.com/icons/rocket.svg",
      url: "https://www.tailwind-kit.com/icons/rocket.svg"
    },
    {
      label: "Youtube",
      icon: "https://www.tailwind-kit.com/icons/rocket.svg",
      url: "https://www.tailwind-kit.com/icons/rocket.svg"
    },
    {
      label: "Tik Tok",
      icon: "https://www.tailwind-kit.com/icons/rocket.svg",
      url: "https://www.tailwind-kit.com/icons/rocket.svg"
    }
  ]
}

export default function TemplateList() {
  const [themes, setThemes] = useState([]);
  const [preview, setPreview] = useState('');
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    api.get('/api/templates/find').then(({ data }) => {
      setThemes(data);
    }).catch(() => {
      toast.error('Houve um erro ao carregar os templates.')
    })
  }, [])

  const onClickVisualize = (e, id) => {
    e.preventDefault();
    demoPage.template_id = id
    if (demoPage.template_id !== '') {
      api.post('/api/pages/generate', demoPage).then(({ data }) => {
        setPreview(data);
        setShowPreview(true);
      }).catch(() => {
        toast.error('Houve um erro ao carregar a preview, tente novamente mais tarde.')
      })
    }
  }

  return (
    <main>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-bookmark">
              <span>Início/ </span>
              <b>Templates</b>
            </div>
          </div>
        </div>
      <div className="row">
        <div className="col-md-12">
          <div className="title">
            <h1>Templates</h1>
            <Link to={`/dashboard/create-template`}>
              <span>Criar novo template</span>
              <button className="btn-new">
                <img src={addNew} alt="sinal de adicionar"/>
              </button>
            </Link>
          </div> 
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <table className="table-list table table-striped">
            <thead>
                <tr>
                  <th>
                    Nome
                  </th>
                  <th >
                    Disponível no Animes
                  </th>
                  <th >
                    Atualizado em
                  </th>
                  <th>
                    Preview
                  </th>
                </tr>
              </thead>
              <tbody>
                {themes.map(theme => (
                  <tr key={theme.id}>
                    <td>
                      <p>
                        {theme.name}
                      </p>
                    </td>
                    <td>
                      <p>
                        {theme.animes_available === true ? 'Sim' : 'Não'}
                      </p>
                    </td>
                    <td>
                      <p>
                        {new Date(theme.updated_at).toLocaleDateString()}
                      </p>
                    </td>
                    <td>
                      <button onClick={(e) => onClickVisualize(e, theme.id)} type="button">
                        Visualizar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
        </div>
      </div>
      {showPreview &&
        <div className="card-page card-template">
          <div className="display-card-page">
            <div className="title-card-page">
              <h2>Preview</h2>
              <button onClick={() => setShowPreview(false)} type="button" className="btn-edit">
                Fechar
              </button>
            </div>
            <iframe title="demo" scrolling="no" srcDoc={preview} />
          </div>
        </div>
      }
    </div>
    </main>
    
  )
}
