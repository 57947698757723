import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import api from "../../services/api";

export default function IconForm() {
  const inputFile = useRef(null);
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    name: '',
    fileName: '',
    icon: null,
  })

  const [preview, setPreview] = useState('');

  const onChange = (event) => {
    event.preventDefault();
    const { name, value, files } = event.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
    if (files) {
      setFormState((prevState) => ({ ...prevState, icon: files[0] }))
      setPreview(URL.createObjectURL(files[0]));
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData()
    formData.append('name', formState.name)
    formData.append('icon', formState.icon)

    api.post('/api/icons', formData).then(({ data }) => {
      toast.success('Ícone cadastrado com sucesso!')
      navigate('/dashboard/icons')
    }).catch((err) => {
      toast.error('Tivemos um problema, tente novamente mais tarde.')
    });
  }

  return (
    <main>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-bookmark bookmark-form">
              <span>Início/ Ícones/ </span>
              <b>Novo ícone</b>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="sub-title title-template">
              Novo Ícone
            </div>
            <div className="new-page-card card-form"> 
              <form>
                <label>Label</label>
                <input name="name" value={formState.name} onChange={onChange} type="text" />
                {preview !== '' &&
                  <img className="img-icon" src={preview} alt={formState.name} />
                }
                <button onClick={() => inputFile.current.click()} type="button">
                  <svg width="20" height="20" fill="currentColor" className="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z">
                    </path>
                  </svg>
                  Selecionar Ícone
                  <input type="file" name="fileName" onChange={onChange} value={formState.fileName} ref={inputFile} className="hidden" />
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="btn-save">
              <button onClick={onSubmit} type="button">
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
