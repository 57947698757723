import Navigator from "../components/Navigator";
import PageForm from "../components/PageForm";

export default function CreatePage() {
  return (
    <div>
      <Navigator />
      <PageForm />
    </div>
  )
}
