import { useEffect, useState } from 'react';
import {
  Navigate, Outlet
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';

const PrivateRoute = () => {
  const [auth, setAuth] = useState();
  const [loading, setLoading] = useState(true);
  const { isAuth } = useAuth();

  useEffect(() => {
    const checkAuth = async () => {
      const auth = await isAuth();
      setAuth(auth);
      setLoading(false);
    }
    checkAuth();
  }, [isAuth])


  return loading ? (
    <>
    </>
  ) : (
    auth ? <Outlet /> : <Navigate to="/login" />
  )
}


export default PrivateRoute;
