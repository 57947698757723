import AppProvider from './hooks';
import Routes from './routes';
import  'bootstrap/dist/css/bootstrap.min.css' ;

function App() {
  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  );
}

export default App;
