import Navigator from "../components/Navigator";
import TemplateList from "../components/TemplateList";

export default function Templates() {
  return (
    <div>
      <Navigator />
      <TemplateList />
    </div>
  )
}
