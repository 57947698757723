import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useParams, useNavigate } from "react-router";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import './pageForm.css'
import { isURL } from "../../utils";

export default function PageForm() {
  const [links, setLinks] = useState([{ id: 1, icon: '', url: '', label: '' }])
  const [icons, setIcons] = useState([]);
  const [themes, setThemes] = useState([])
  const [currentTheme, setCurrentTheme] = useState([])
  const [preview, setPreview] = useState('');
  const [avatarPreview, setAvatarPreview] = useState('');
  const inputFile = useRef(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const { username } = useParams();

  const { getUser } = useAuth();

  const [formState, setFormState] = useState({
    name: '',
    username: '',
    title: '',
    template_id: '',
    bio: '',
    avatar: '',
    fileName: '',
  })

  useEffect(() => {
    const getUserData = async () => {
      const user = await getUser();
      setUser(user);
    }
    getUserData();
  }, [getUser])

  useEffect(() => {
    if (username !== undefined) {
      api.get(`/api/pages/${username}`)
        .then(({ data }) => {
          setFormState({
            name: data.name,
            username: data.username,
            bio: data.bio,
            title: data.title,
            template_id: data.template_id,
          })
          const tmpLinks = []
          data.links.map(link => {
            tmpLinks.push({ ...link, valid: true })
          })
          setLinks(tmpLinks)
          if (data.avatar && data.avatar !== '') setAvatarPreview(data.avatar);
        }).catch(() => {
          toast.error('Houve um erro ao carregar os dados.')
          navigate('/dashboard');
        })
    }
  }, [username, navigate])

  const onChange = (event) => {
    event.preventDefault();
    const { name, value, files } = event.target;
    if (name === 'template_id') {
      const theme = themes.filter(t => t.id === value);
      setCurrentTheme(theme.name);
    }
    if (files) {
      setFormState((prevState) => ({ ...prevState, avatar: files[0] }))
      setAvatarPreview(URL.createObjectURL(files[0]));
    }
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  }

  const onChangeLinks = (event, id) => {
    const { name, value } = event.target;
    const currentLink = links.filter(link => link.id === id)[0];
    const remainingLinks = links.filter(link => link.id !== id);
    const updatedLink = Object.assign(currentLink, { id: id, [name]: value })
    const sortedLinks = [...remainingLinks, updatedLink].sort((a, b) => a.id - b.id)
    setLinks(sortedLinks);
    checkLinks();
  }

  useEffect(() => {
    if (user && user.attributes['custom:animes_access'] === '1') {
      api.get('/api/templates/find').then(({ data }) => {
        setThemes(data);
      }).catch(() => {
        toast.error('Houve um erro ao carregar os dados.')
      })
    } else {
      api.get('/api/templates/no-animes-access').then(({ data }) => {
        setThemes(data);
      }).catch((err) => {
        toast.error('Houve um erro ao carregar os dados.')
      })
    }
    api.get('/api/icons/find').then(({ data }) => {
      data.map(icon => icon.label = icon.name)
      setIcons(data)
    }).catch((err) => {
      toast.error('Houve um erro ao carregar os dados.')
    })
  }, [user])

  const onBlur = (e) => {
    const page = { ...formState, avatar: avatarPreview ? avatarPreview : '', links: links };
    if (page.template_id !== '') {
      api.post('/api/pages/generate', page).then(({ data }) => {
        setPreview(data);
      }).catch(() => {
        toast.error('Houve um erro ao carregar a preview.')
      })
    }
  }

  const checkLinks = () => {
    links.map(link => {
      let urlValid = false;
      if (!isURL(link.url)) {
        urlValid = false;
      } else if (link.url === '') {
        urlValid = true;
      } else urlValid = true;
      const remainingLinks = links.filter(l => l.id !== link.id);
      const updatedLink = Object.assign(link, { id: link.id, valid: urlValid })
      const sortedLinks = [...remainingLinks, updatedLink].sort((a, b) => a.id - b.id)
      setLinks(sortedLinks);
    })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      onBlur()
    }, 500)

    return () => clearTimeout(timer)
  }, [formState, links])

  const onSubmit = (e) => {
    e.preventDefault();
    delete formState.fileName;
    const formattedLinks = links;
    formattedLinks.map(link => {
      if (typeof link.id === "number")
        delete link.id;
      return []
    })
    const formData = new FormData();
    formData.append('name', formState.name)
    formData.append('username', formState.username)
    formData.append('title', formState.title)
    formData.append('avatar', formState.avatar)
    formData.append('bio', formState.bio)
    formData.append('template_id', formState.template_id)
    formData.append('url', `${process.env.REACT_APP_API}/${formState.username}`)
    formData.append('cognito_user', user.attributes.sub)
    for (let i = 0; i < links.length; i++) {
      if (!links[i].valid) {
        toast.error('As urls precisam estar no formato http(s)://url.com ou http(s)://www.url.com!')
        return;
      }
      if (links[i].id) formData.append(`links[${i}][id]`, links[i].id);
      formData.append(`links[${i}][label]`, links[i].label);
      formData.append(`links[${i}][icon]`, links[i].icon);
      formData.append(`links[${i}][url]`, links[i].url);
    }
    if (formState.template_id !== '') {
      if (username) {
        api.put(`/api/pages/${username}`, formData).then(({ data }) => {
          toast.success('Página salva com sucesso!')
          window.open(`${process.env.REACT_APP_API}/${formState.username}`, '_blank').focus();
          navigate('/dashboard')
        }).catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message)
          } else {
            toast.error('Houve um erro ao salvar os dados, tente novamente mais tarde')
          }
        });
      } else {
        api.post('/api/pages', formData).then(({ data }) => {
          toast.success('Página salva com sucesso!')
          window.open(`${process.env.REACT_APP_API}/${formState.username}`, '_blank').focus();
          navigate('/dashboard')
        }).catch((err) => {
          if (err.response) {
            toast.error(err.response.data.message)
          } else {
            toast.error('Houve um erro ao salvar os dados, tente novamente mais tarde')
          }
        });
      }
    }
  }

  return (
    <main>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-bookmark bookmark-form">
              <span>Minhas páginas / </span>
              <b>Nova página</b>
            </div>
          </div>
        </div>
       <div className="row">
        <div className="col-md-6">
          <div className="sub-title">
            <h2>Perfil</h2>
          </div>
          <div className="new-page-card card-perfil">
            <form onBlur={onBlur}>
              <label>Nome</label>
              <input name="name" value={formState.name} onChange={onChange} type="text"/>

              <label>Nome do usuário</label>
              <input name="username" value={formState.username} onChange={onChange} type="text" disabled={username !== undefined ? true : false} />
              
              <label>Título</label>
              <input name="title" value={formState.title} onChange={onChange} type="text"/>

              <label>Tema</label>
              <select name="template_id" value={formState.template_id} onChange={onChange}>
                {themes.map((theme) => (
                  <option key={theme.id} value={theme.id}>
                    {theme.name}
                  </option>
                ))}
              </select>

              <label htmlFor="name">Bio</label>
              <textarea name="bio" value={formState.bio} onChange={onChange} id="comment" rows="5" cols="40"></textarea>

              <button onClick={() => inputFile.current.click()} type="button">
                <svg width="20" height="20" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28h-1472q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-448h-256q-42 0-59-40-17-39 14-69l448-448q18-19 45-19t45 19l448 448q31 30 14 69z">
                  </path>
                </svg>
                  Selecionar Imagem de Perfil
                <input type="file" name="fileName" onChange={onChange} value={formState.fileName} ref={inputFile} className="hidden" />
              </button>
            </form>
          </div>

          <div className="sub-title">
            <h2>Links</h2>
          </div>
          <div className="new-page-card">
            <form onBlur={onBlur}>
              {links.map(link => {
                return <div key={link.id} className="card-links">
                  
                  <label>Nome</label>
                  <input name="label" value={link.label} onChange={(e) => onChangeLinks(e, link.id)} type="text"/>

                  <label>Ícone</label>
                  <select name="icon" value={link.icon} onChange={(e) => onChangeLinks(e, link.id)} >
                    {icons.map((icon) => (
                      <option key={icon.id} value={icon.src}>
                        {icon.name}
                      </option>
                    ))}
                  </select>

                  <label>URL</label>
                  <input name="url" value={link.url} onChange={(e) => onChangeLinks(e, link.id)} type="text" className={!link.valid ? 'border-red-500' : ''}/>
                    {links.length > 1 &&
                      <button type="button" onClick={() => setLinks(links.filter((item) => item.id !== link.id))} className="btn-remove-link">
                        Remover
                      </button>
                    }
                  </div>
              })}
              <button onClick={(e) => { e.preventDefault(); setLinks([...links, { id: links.length + 1 }]) }} className="btn-add-link">
                Adicionar
              </button>
            </form>
          </div>
        </div>
        <div className="col-md-6">
          <div className="sub-title title-preview">
            <h2>Preview</h2>
          </div>
          <div className="card-preview">
            <iframe title={formState.username} scrolling="no" srcDoc={preview} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="btn-save">
            <button onClick={onSubmit} type="button">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
  )
}
