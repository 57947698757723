import React from 'react';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './auth';

const AppProvider = ({ children }) => (
  <AuthProvider>
    <Toaster />
    {children}
  </AuthProvider>
);

export default AppProvider;
