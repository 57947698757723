import { useEffect, useState } from "react";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import { Link } from "react-router-dom";
import './pageList.css'
import addNew from '../../assets/sinal-adicionar.svg'
import toast from "react-hot-toast";

export default function PageList() {
  const [pages, setPages] = useState([]);

  const { getUser } = useAuth();

  useEffect(() => {
    const getPages = async () => {
      const user = await getUser();
      api.get(`/api/pages/user/${user.attributes.sub}`).then(({ data }) => {
        setPages(data);
      }).catch(() => {
        toast.error('Houve um erro ao carregar as páginas.')
      })
    }
    getPages();
  }, [getUser])

  return (
    <main>
      <div className="container">
        <div className="row">
        <div className="col-md-12">
          <div className="title">
            <h1>Minhas páginas</h1>
            <Link to={`/dashboard/new`}>
              <span>Criar nova página</span>
              <button className="btn-new">
                <img src={addNew} alt="sinal de adicionar"/>
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="display-my-pages">
            {pages.map(page => (
              <div key={page.id} className="card-page">
                <div className="display-card-page">
                  <div className="title-card-page">
                    <h2>{page.title}</h2>
                    <Link to={`/dashboard/edit/${page.username}`}>
                      <button className="btn-edit">
                        Editar
                      </button>
                    </Link>
                    <a target="_blank" rel="noreferrer" href={page.url}>
                      <button className="btn-view">
                      Ver
                      </button>
                    </a>
                  </div>
                  <iframe title={page.username} scrolling="no" srcDoc={page.html} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> 
    </div>
    </main>
  )
}
