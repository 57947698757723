import IconForm from '../components/IconForm';
import Navigator from '../components/Navigator';

export default function CreateIcon() {
  return (
    <div>
      <Navigator />
      <IconForm />
    </div>
  )
}
