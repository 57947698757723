import { Fragment } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import CreateIcon from '../pages/create-icon';
import CreatePage from '../pages/create-page';
import CreateTemplate from '../pages/create-template';
import Icons from '../pages/icons';
import Login from '../pages/login';
import Me from '../pages/me';
import Templates from '../pages/templates';
import PrivateRoute from './PrivateRoute';

const CustomRoutes = () => (
  <Router>
    <Fragment>
      <Routes>
        <Route path="/login" exact element={<Login />} />
        <Route exact path='/' element={<PrivateRoute />}>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route exact path='/dashboard' element={<Me />} />
          <Route exact path='/dashboard/new' element={<CreatePage />} />
          <Route exact path='/dashboard/edit/:username' element={<CreatePage />} />
          <Route exact path='/dashboard/templates' element={<Templates />} />
          <Route exact path='/dashboard/create-template' element={<CreateTemplate />} />
          <Route exact path='/dashboard/icons' element={<Icons />} />
          <Route exact path='/dashboard/create-icon' element={<CreateIcon />} />
        </Route>
      </Routes>
    </Fragment>
  </Router>
);

export default CustomRoutes;