import { Amplify, Auth } from 'aws-amplify';
import { createContext, useCallback, useContext } from 'react';
import toast from 'react-hot-toast';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL,
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT,
  }
});

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const getUser = useCallback(async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    if (currentUser) {
      return currentUser;
    } else return null;
  }, [])

  const isAuth = useCallback(async () => {
    try {
      await Auth.currentSession();
      const currentUser = await Auth.currentAuthenticatedUser();
      if (!currentUser.attributes['custom:minebio_access']) {
        Auth.updateUserAttributes(currentUser, {
          'custom:minebio_access': '1',
        })
      }
      return true;
    } catch (err) {
      return false
    }
  }, [])

  const signUp = useCallback(async (email, password) => {
    try {
      await Auth.signUp({
        username: email,
        password: password,
      });
    } catch (err) {
      if (err.name === 'UsernameExistsException')
        toast.error('Usuário já cadastrado.')
      else if (err.name === 'InvalidParameterException')
        toast.error('Erro ao cadastrar usuário.')
    }
  }, [])

  const confirmCode = useCallback(async (email, code) => {
    try {
      await Auth.confirmSignUp(email, code)
    } catch (err) {
      toast.error('Código incorreto ou expirado.')
    }
  }, [])

  const logIn = useCallback(async (email, password) => {
    try {
      await Auth.signIn(email, password)

      window.location.href = '/dashboard';
    } catch (err) {
      if (err.name === 'UserNotFoundException')
        toast.error('Usuário informado não existe.')
      else toast.error('Usuário ou senha incorretos.')
    }
  }, [])

  const logOut = useCallback(async () => {
    try {
      await Auth.signOut();
      window.location.href = '/dashboard/login';
    } catch (err) {
      console.log('error signing out: ', err);
    }
  }, [])

  return (
    <AuthContext.Provider value={{ getUser, isAuth, signUp, confirmCode, logIn, logOut }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}